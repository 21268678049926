import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getPdf} from '../../redux/modules/sign-in'
import {api2} from '../../redux/config'
import md5 from 'js-md5'

class Pdf extends Component {
  componentDidMount() {
    const id = this.props.user.id + ''
    this.props.getPdf(md5(id))
  }
  render() {
    const { mobile,img } = this.props
    if (!img) return null
    return (
      <div className="pdf">
        <img src={api2 + img} alt=""/>
          <a className="pdf__link1" href={api2 + img + '?download=true'} target="_blank"><img src="/images/download.svg" alt=""/></a>
          <div className="pdf__link2" onClick={this.handlePrint} target="_blank" ><img src="/images/print.svg" alt=""/></div>
      </div>
    )
  }

  handlePrint = () => window.print()
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    img: state.auth.url,
    user: state.auth.user
  }),
  {getPdf}
)(Pdf)

import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLevel } from '../../redux/modules/sign-in'
import Grandma from './grandma'

const emptyBoard = [
  
//[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2,],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 4, 4, 2, 2, 0, 0, 0, 0, 4, 1, 1, 1, 1, 4, 0, 2, 2, 2, 0, 0, 6, 1, 1, 1, 1, 1, 1, 4, 0, 2, 0, 0, 0, 0, 4, 1, 1, 1, 1, 1, 4, 2, 2, 2, 1, 1],
  [1, 0, 0, 0, 0, 0, 3, 1, 1, 1, 1, 1, 1, 3, 2, 0, 0, 0, 3, 1, 1, 1, 1, 1, 1, 1, 4, 0, 0, 0, 0, 0, 3, 1, 1, 1, 1, 1, 1, 3, 2, 0, 0, 0, 0, 3, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 0, 0, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
]
const defaultPlayer = {
  score: 0,
  side_direction: 'right',
  direction: 'right',
  x: 1,
  y: 3 
}
const position = [
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},

  {x:9, y: 2},
  {x:9, y: 2},
  {x:9, y: 2},
  {x:9, y: 2},

  {x:8, y: 2},
  {x:8, y: 2},
  {x:8, y: 2},
  {x:8, y: 2},

  {x:8, y: 2},
  {x:8, y: 2},
  {x:8, y: 2},
  {x:8, y: 2},

  {x:9, y: 2},
  {x:9, y: 2},
  {x:9, y: 2},
  {x:9, y: 2},

  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:10, y: 2},
  {x:11, y: 2},
  {x:12, y: 2},

  {x:13, y: 2},
  {x:13, y: 3},
  {x:13, y: 3},

  {x:14, y: 3},
  {x:15, y: 3},
  {x:16, y: 3},
  {x:17, y: 3},
  {x:18, y: 3},
  {x:18, y: 3},
  {x:18, y: 3},
  {x:18, y: 3},
  {x:18, y: 3},
  {x:19, y: 3},
  {x:20, y: 2},
  {x:20, y: 2},
  {x:20, y: 2},
  {x:20, y: 2},
  {x:20, y: 2},
  {x:20, y: 2},
  {x:20, y: 2},
  {x:20, y: 2},
  {x:21, y: 2},
  {x:22, y: 2},
  {x:23, y: 2},
  {x:24, y: 2},
  {x:25, y: 2},
  {x:25, y: 2},
  {x:25, y: 2},
  {x:25, y: 2},
  {x:25, y: 4},
  {x:26, y: 4},
  {x:26, y: 4},
  {x:26, y: 4},
  {x:26, y: 4},
  {x:24, y: 4},
  {x:23, y: 4},
  {x:22, y: 4},
  {x:22, y: 4},
  {x:22, y: 4},
  {x:22, y: 4},
  {x:22, y: 4},
  {x:22, y: 4},
  {x:22, y: 4},
  {x:22, y: 4},



] 
const defaultPepper = {
  direction: '_left',
  x:14, y: 2
}

class Pacman extends Component {
  state = {
    start: false,
    attempts: 3,
    board: emptyBoard,
    player: {...defaultPlayer},
    pepper: {...defaultPepper},
    candyAnimation: false,
    init: false,
    showBtn: true,
    win: false,
    styles: {transform: 'translate(-2.32558%, -40%)'}
  }

  id = null
  tid = null
  tid2 = null
  tid3 = null
  tid4 = null

  pepper = () => {    
    let reverse = 1
    const pepperLoop = (i) => {
      if (this.state.player.score === 10 ) {
        clearTimeout(this.tid)
        return false
      }
      if (position[i]) {
        this.setState({
          pepper: {...this.state.pepper, ...position[i]}
        })
        this.tid = setTimeout(() => pepperLoop(i+reverse), 200)
      }
      if (i  === position.length - 1)  reverse = -1
      if (i  === 0)  reverse = 1
    }
    pepperLoop(0);
  }

  componentWillUnmount() {
    clearTimeout(this.id)
    clearTimeout(this.tid)
    clearTimeout(this.tid2)
    clearTimeout(this.tid3)
    clearTimeout(this.tid4)
    clearTimeout(this.ttt)
    clearInterval(this.pid)
    window.removeEventListener('keydown', this.checkArrow)
    window.removeEventListener('keyup', this.pauseAnimation) 
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.player.score === 10 && this.state.player.score !== prevState.player.score) {
      if (this.props.user.step < 5) this.props.setLevel(this.props.user, 5 ) 
      this.tid3 = setTimeout(() => this.setState({win: true}), 200)
    }
    if (this.state.candyAnimation && this.state.candyAnimation !== prevState.candyAnimation) {
      this.tid2 = setTimeout(() => this.setState({candyAnimation: false}), 600)
    }
    if (this.state.jump && this.state.jump !== prevState.jump) {
      this.tid2 = setTimeout(() => this.setState({jump: false}), 200)
    }


    if (
      prevState.pepper.x !== this.state.pepper.x ||
      prevState.pepper.y !== this.state.pepper.y ||
      prevState.player.x !== this.state.player.x ||
      prevState.player.y !== this.state.player.y
    ) {
        if (this.state.pepper.x === this.state.player.x && this.state.pepper.y === this.state.player.y) {
          clearTimeout(this.ttt)
          clearInterval(this.pid)
          this.setState({attempts: this.state.attempts - 1, animateFail: true, animated: false})
        }
    }

    if (this.state.attempts != prevState.attempts) {
      this.tid4 = setTimeout(() => {
        this.setState({
          board: emptyBoard,
          player: {...defaultPlayer},
          // pepper: {...defaultPepper}, 
          styles: {transform: 'translate(-2.32558%, -40%)'}, 
          animateFail: false
        })
        // this.pepper()
      }, 1000)
    }


    if (this.state.pepper.x !==  prevState.pepper.x ) {
      if (this.state.pepper.x <  prevState.pepper.x && this.state.pepper.direction === '_left' ) {
        this.setState({
          pepper: {...this.state.pepper, direction: '_right' }, 
        })
      }
      if (this.state.pepper.x >  prevState.pepper.x && this.state.pepper.direction === '_right' ) {
        this.setState({
          pepper: {...this.state.pepper, direction: '_left' }, 
        })
      }
    }

  }

  render() {
    if (this.state.win) return (
      <div className={this.props.mobile ? 'layer area' : 'layer game__area'}> 
        <img src="/images/game/area2.png" alt=""/>
        <Grandma />

        <div className="attempts">
          {this.state.attempts === 3 ? <span /> : <span className="fail"><i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i><i></i></span>}
          {this.state.attempts > 1 ? <span /> : <span className="fail"><i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i><i></i></span>}
          {this.state.attempts > 0 ? <span /> : <span className="fail"><i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i><i></i></span>}
        </div>
        <div className="score"><span>{this.state.player.score} -</span></div>
        <Link to="/certificate"  className="layer button button--10"><img src="/images/button/10.svg" alt=""/></Link>
      </div>
    )
    return (
      <div className="layer area">
        <img src="/images/game/board.png" alt=""/>
        {this.board()}
        <div className="attempts">
          {this.state.attempts === 3 ? <span /> : <span className="fail"><i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i><i></i></span>}
          {this.state.attempts > 1 ? <span /> : <span className="fail"><i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i><i></i></span>}
          {this.state.attempts > 0 ? <span /> : <span className="fail"><i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i> <i></i><i></i></span>}
        </div>
        <div className={this.state.candyAnimation ? `score animated` : 'score'}><span>{this.state.player.score} -</span> <i>{this.state.player.score}</i><b /></div>
        {this.state.showBtn && <div className="layer button button--9" onClick={this.play}><img src="/images/button/9.svg" alt=""/></div>}
        {this.state.attempts === 0 && <div className="layer gameover"><img src="/images/game/game-over.png" alt=""/></div>}
        {this.state.attempts === 0 && <div className="layer button button--9" onClick={this.newPlay}><img src="/images/button/9.svg" alt=""/></div>}
        {this.props.mobile && !this.state.showBtn && this.state.attempts && <div className="layer packman__left-arrow" onMouseUp={this.pauseAnimation} onMouseDown={this.handleLeftArrow}><img src="/images/game/mob/left-arrow.png" alt=""/></div>}
        {this.props.mobile && !this.state.showBtn && this.state.attempts && <div className="layer packman__right-arrow" onMouseUp={this.pauseAnimation} onMouseDown={this.handleRightArrow}><img src="/images/game/mob/right-arrow.png" alt=""/></div>}
      </div>
    )
  }

  handleLeftArrow = () => {
    if (!this.state.start || this.state.attempts === 0) return false
    this.checkArrow({keyCode: 37})
    // setTimeout(() => {this.pauseAnimation()}, 100)
  }
  handleRightArrow = () => {
    if (!this.state.start || this.state.attempts === 0) return false
    this.checkArrow({keyCode: 39})
    // setTimeout(() => {this.pauseAnimation()}, 100)
  }
  handleJump = () => {
    if (!this.state.start) return false
    this.checkArrow({keyCode: 32})
  }

  newPlay = async() => {
    clearInterval(this.pid)
    clearTimeout(this.ttt)
    await this.setState({
      attempts: 3,
      player: {...defaultPlayer},
      animated: false
      // pepper: {...defaultPepper},
    })
  }

  ttt = null
  pauseAnimation = () => {
    this.ttt = setTimeout(() => {
      clearInterval(this.pid)
      this.setState({animated: false})
    }, this.props.mobile ? 400 : 150)

  }

  play = () => {
    window.addEventListener('keydown', this.checkArrow) 
    window.addEventListener('keyup', this.pauseAnimation) 
    this.pepper()
    this.setState({showBtn: false, start: true})
  }



  board = () => {
    const {board, player, pepper} = this.state
    var xPercent = pepper.x * 100 / this.state.board[0].length
    var yPercent = pepper.y * 100 / this.state.board.length
    const pepperStl = {
      left: pepper.x * 100 / this.state.board[0].length + '%',
      top: (pepper.y - 1) * 100 / (this.state.board.length - 1) + '%',
      transition: pepper.transition ? pepper.transition + 'ms' : '200ms'
    }

    const rows = board.map((item, i) => {
      return <div key={i} className='row'>{this.square(item, this.state.player.side_direction)}</div>
    })
    return (
      <div className='layer board'>
        <div className="rows" style={{...this.state.styles}}>
          {rows}
          <div className={`layer pepper pepper${this.state.pepper.direction}`} style={{...pepperStl}}>
            <img src="/images/game/square-spacer.png" alt=""/>
          </div> 
        </div>
        <div className={`layer packman packman_${this.state.player.side_direction}${this.state.animateFail ? ' fail-naimation' : ''}${this.state.animated ? ' animated' : ''}${this.state.candyAnimation ? ' candy-animation' : ''}${this.state.jump ? ' jump' : ''}`} >
          <div className="packman-img"><span></span><span></span><span></span></div>
          <img src="/images/game/square-spacer.png" alt=""/>
          <div className="x5"></div>
        </div> 
      </div>
    )
  }

  pid = null

  checkArrow = async(event) => {
    clearTimeout(this.ttt)
    if (this.state.attempts === 0 || this.state.player.score === 10 || this.state.animateFail ) return false
    const keyValue = event.keyCode
    const left = 37
    const up = 38
    const right = 39
    const down = 40
    if (!this.props.mobile) event.preventDefault();
    if (keyValue === left) await this.setState({player: {...this.state.player, direction: 'left', side_direction: 'left'}})
    if (keyValue === right) await this.setState({player: {...this.state.player, direction: 'right', side_direction: 'right'}})
    if (keyValue === up || keyValue === 32) {
      await this.setState({jump: true})
      return false
    }
    // if (keyValue === down) await this.setState({player: {...this.state.player, direction: 'bottom'}})
    // await this.player()
    if (this.state.animated) return false
    this.setState({animated: true})
    clearInterval(this.pid)
    this.pid = setInterval(() => {this.player()}, 100)

  }

  checkCollision = (x, y, direction) => {
    const { board } = this.state
    let value = null

    if (direction === 'right') value = board[y][x + 1]
    if (direction === 'left') value = board[y][x - 1]
    // if (direction === 'bottom') value = board[y + 1][x]
    if (direction === 'top') value = board[y - 1][x]

    return value
  }

  checkTunnel = (x, y, dir) => {
    let xMax = this.state.board[0].length - 1
    if (x === 0 && dir === 'left') this.setState({player: {...this.state.player, x: xMax}})
    if (x === (xMax) && dir === 'right') this.setState({player: {...this.state.player, x: 0}})
  }
   
  player = () => {
    const { player, pepper, board} = this.state
    let direction = player.direction
    let x = player.x
    let y = player.y
    let styles = {}

    let collisionVal = this.checkCollision(x, y, direction)

    if (collisionVal !== 1) {
      if (direction === 'right' && x < (this.state.board[0].length - 1)) x += 1
      if (direction === 'left' && x > 0) x -= 1

      const xPercent = x * 100 / this.state.board[0].length
      const yPercent = (y - 1) * 100 / (this.state.board.length - 1)
      const styles = {
        transform: 'translate(' + (-xPercent) + '%,'+ (-yPercent) +'%)',
      }

      this.setState({player: {...this.state.player, x: x, y: y}, styles})
      
      if (collisionVal === 2) {
        this.setState({
          player: {
            ...this.state.player, 
            score: this.state.player.score + 1,
          },
          board: this.newBoard(x, y, 0),
          candyAnimation: true
        })
      }
    }

    
    this.checkTunnel(x, y, direction)


    if (collisionVal === 3 || collisionVal === 4 || collisionVal === 6) {
      clearInterval(this.pid)
      clearTimeout(this.ttt)

      this.id = setTimeout(() => {
        y = collisionVal === 3 ? this.state.player.y - 1 : collisionVal === 4 ? this.state.player.y + 1 : this.state.player.y + 2
        const xPercent = x * 100 / this.state.board[0].length
        const yPercent = (y - 1) * 100 / (this.state.board.length - 1)
        const styles = { transform: 'translate(' + (-xPercent) + '%,'+ (-yPercent) +'%)'}

        this.setState({styles, animated: false})
      }, 100)

      this.id = setTimeout(() => {
        const xPercent = x * 100 / this.state.board[0].length
        const yPercent = (y - 1) * 100 / (this.state.board.length - 1)
        const styles = { transform: 'translate(' + (-xPercent) + '%,'+ (-yPercent) +'%)'}
        this.setState({player: {...this.state.player, x: x, y: y}, styles, animated: false})
        this.player()
      }, 200)
    }

  }

  newBoard = (x, y, value) => this.state.board.reduce((acc, val) => {
      if (acc.length === y) {
        const newVal = val.reduce((a, v) => {
          if (a.length === x) return [...a, value]
          return [...a, v]
        }, [])
        return [...acc, newVal]
      }
      return [...acc, val]
    }, [])

  square = (square, direction) => {
    let classVal = 'square'
    let classVal2 = direction === 'right' ? 'square square__right' : 'square square__left'
    const squares = square.map((item, i) => {
      if (item === 0 || item === 4) classVal = classVal2
      if (item === 1) classVal = 'square wall'
      if (item === 2) classVal = 'square dot'

      return <div key={i} className={classVal}><img src="/images/game/square-spacer.png" alt=""/></div>
    })
    return squares
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    user: state.auth.user
  }),
  { setLevel }
)(Pacman)

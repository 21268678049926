/**
 * Constants
 * */
export const moduleName = 'modal'

export const POLICY = `${moduleName}/POLICY`
// export const DRAW = `${moduleName}/DRAW`
export const OPEN = `_OPEN`
export const CLOSE = `_CLOSE`

/**
 * Reducer
 * */
const defaultState = {
  policy: false,
  // draw: true
}

export default (state = defaultState, action) => {
  const { type } = action

  switch (type) {
    case POLICY + OPEN:
      return {...state, policy: true }
      
    case POLICY + CLOSE:
      return {...state, policy: false }

    // case DRAW + OPEN:
    //   return {...state, draw: true }
      
    // case DRAW + CLOSE:
    //   return {...state, draw: false }

    default:
      return state
  }
}


/**
 * Action Creators
 * */

export const policyOpen = () => ({ type: POLICY + OPEN })
export const policyClose = () => ({ type: POLICY + CLOSE })
// export const drawOpen = () => ({ type: DRAW + OPEN })
// export const drawClose = () => ({ type: DRAW + CLOSE })

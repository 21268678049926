import React from 'react'
import PropTypes from 'prop-types'

function Input({value, name, type, valid, setFieldValid, change, validate, trigger}) {
  const limits = {
    name: {
      regex: /^[а-яА-Яa-zA-Z]+$/
    },
  }

  const getClassName = () => {
    const validCl = valid || (!value && !trigger) ? 'valid' : 'error'
    return 'input-field text-field ' + validCl
  }

  const isValidField = () => {
    return !!value
    // const limit = limits[validate]
    // if (!limit) return true

    // if (limit.regex && !limit.regex.test(value)) {
    //   return false
    // }

    // if (limit.min && value.length < limit.min) {
    //   return false
    // }

    // return true
  }

  const handleBlur = (ev) => {
    setFieldValid(name, isValidField())
  }

  const handleFocus = (ev) => {
    setFieldValid(name, true)
  }

  return (
    <div className={getClassName()}>
      <input
          autoсomplete="off"
          name={name}
          type={type}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          onChange={change}
        />
    </div>
  )
}

Input.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,

  setFieldValid: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  validate: PropTypes.string,
  trigger: PropTypes.bool,
}

export default  Input

export default (store) => (next) => (action) => {
  const { setLocalStorage, getLocalStorage, removeLocalStorage, ...rest } = action

  if (!setLocalStorage && !getLocalStorage && !removeLocalStorage) return next(action)

  if (setLocalStorage) {
    let { value, name } = setLocalStorage

    localStorage.setItem(name, JSON.stringify(value))
    return next({ ...rest })
  }

  if (removeLocalStorage) {
    localStorage.removeItem(removeLocalStorage)
    return next({ ...rest })
  }

  const storageValue = JSON.parse(localStorage.getItem(getLocalStorage))

  next({ ...rest, storageValue })
}

import { useState } from "react"

export default (initialValue = {}) => {
  const [state, setState] = useState(initialValue)

  const handleChange = (ev) => {
    const { name, value } = ev.target
    setState({ ...state, [name]: { ...state[name], value } })
  }

  const setFieldValid = (name, valid) => {
    setState({ ...state, [name]: { ...state[name], valid } })
  }

  const formIsValid = () => {
    return Object.keys(state).every((name) => {
      const field = state[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  return [state, handleChange, setFieldValid, formIsValid]
}

import React, { useState, useEffect, Fragment } from "react"
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import InputField from '../common/input'
import SelectField from '../common/select-field'
import useForm from '../../hooks/use-form'
import UserPic from '../common/userpic'
import { signIn, openCreatePic, changeGender } from '../../redux/modules/sign-in'
import {policyOpen} from '../../redux/modules/modal'

function Form() {
  const dispatch = useDispatch()
  const [trigger, setTrigger] = useState(false)
  const [bubble, setBubble] = useState(false)
  const user = useSelector((state) => state.auth.user)
  const mobile = useSelector((state) => state.mobile)
  const open = useSelector((state) => state.auth.pic)



  const [fields, handleChange, setFieldValid, formIsValid, passwordToggle] = useForm({
    name: {
      value: user.name,
      name: 'name',
      type: 'text',
      validate: 'name',
      valid: user.name ? true : false
    },
    gender: {
      value: user.gender,
      name: 'gender',
      valid: true,
      options: [
        {label: 'Мальчик', value: 'm'},
        {label: 'Девочка', value: 'f'},
      ],
    },
    parents: {
      value: user.parents,
      name: 'parents',
      valid: true,
      options: [
        {label: 'Мама', value: '1'},
        {label: 'Папа', value: '2'},
        {label: 'Брат', value: '3'},
        {label: 'Сестра', value: '4'},
        {label: 'Дядя', value: '5'},
        {label: 'Тётя', value: '6'},
      ],
    }
  })
  useEffect(() => {
    if (fields.name.value) setBubble(false)
  }, [fields.name.value])

  // useEffect(() => {
  //   if (fields.name.value) setBubble(false)
  //   const handleScroll = () => {
  //     if (!fields.name.value) {
  //       setBubble(true)
  //       window.removeEventListener('scroll', handleScroll)
  //     }
  //   }
  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [fields.name.value])

  const handleSend = async () => {
    setTrigger(true)
    if (!formIsValid()) {
      if (!fields.name.value) setBubble(true)
      return false
    }
    // if (!user.userpic) return dispatch(openCreatePic(fields.gender.value))
    await dispatch(signIn({
      name: fields.name.value,
      gender: fields.gender.value,
      parents: fields.parents.value,
      userpic: user.userpic ? user.userpic : [1,5],
      step: user.step,
    }))
    dispatch(push('/make-candy'))
  }

  return (
    <Fragment>
    <div className="form">
      <div>
        <div className="form-title">Пропуск</div>
        <div className="form-text">Чтобы попасть в офис Х5, тебе нужен пропуск!</div>
        <div className="form-content">
          <div>
            <div className="photo-wrap"   onClick={() => dispatch(openCreatePic(fields.gender.value))}>
              <div className="photo">
                <img src="/images/sign-in/photo-border.png" alt=""/>
                <UserPic />
              </div>
                {user.userpic && !open && <span className='accepted'><img src="/images/sign-in/accepted.png" alt=""/></span>}
              <div className="photo__info">Как ты выглядишь? <br/> <span className="link">Создать аватарку</span></div>
            </div>
          </div>
          <div>
            <div className="field-title">Как тебя зовут?</div>
            <InputField
              trigger={trigger}
              setFieldValid={setFieldValid}
              change={handleChange}
              {...fields.name}
            />
            <div className="field-title">Ты мальчик или девочка?</div>
            <SelectField
              trigger={trigger}
              setFieldValid={setFieldValid}
              change={handleChange}
              cl="select-field1"
              callback={changeGender}
              {...fields.gender}
            />
            <div className="field-title">У тебя в Х5 работает:</div>
            <SelectField
              trigger={trigger}
              setFieldValid={setFieldValid}
              change={handleChange}
              cl="select-field2"
              {...fields.parents}
            />
          </div>
        </div>
        <div className="layer button button--2" onClick={handleSend}><img src="/images/button/2.svg" alt=""/></div>
        {/*<div className="btn" onClick={handleSend} >
          <span>Готово!</span>
        </div>*/}
        {/*<div className="form-text-bottom">При оформлении пропуска, вы солгашаетесь с <span className="link" onClick={() => dispatch(policyOpen())}>правилами</span>.</div>*/}
      </div>
    </div>
    {bubble ? <div className="layer sign-in__bubble2"><img src="/images/sign-in/bubble2.png" alt=""/></div> : null}
    {bubble && mobile ? <div className="layer sign-in__robot2"><img src="/images/sign-in/mob/robot.png" alt=""/></div> : null}
    </Fragment>
  )
}

export default Form
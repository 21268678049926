import { push, goBack } from 'connected-react-router'
/**
 * Constants
 * */
export const moduleName = 'loading'

export const LOADING = `${moduleName}/LOADING`
export const PLAN = `${moduleName}/PLAN`

/**
 * Reducer
 * */
const defaultState = {
  signin: true,
  candy: true,
  eat: true,
  draw: true,
  game: true,
  certificate: true,
  plan: true,
  planOpen: true
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOADING:
      return {...state, [payload.field]: false }
      
    case PLAN:
      return {...state, planOpen: false }

    default:
      return state
  }
}


/**
 * Action Creators
 * */

export const setLoading = (field) => ({ type: LOADING, payload: {field} })

export const getPage = (url) => (dispatch) => dispatch(push(url))

export const getBack = () => (dispatch) => dispatch(goBack())

export const closePlan = () => ({ type: PLAN })


import React, { Component } from 'react'
import { connect } from 'react-redux'

class Grandma extends Component {
  render() {
    const mob = this.props.mobile ? 'mob/' : ''
    return (
      <div className="grandma">
        <div className="layer grandma__samovar"><img src={`/images/game/${mob}samovar.png`} alt=""/></div>
        <div className="layer grandma__grandma"><img src={`/images/game/${mob}grandma.png`} alt=""/></div>
        <div className="layer grandma__player"><img src="/images/game/player.png" alt=""/></div>
        <div className="layer grandma__bubble"><img src="/images/game/bubble2.png" alt=""/></div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
  }),
  {}
)(Grandma)

/**
 * Constants
 * */
export const moduleName = 'height'
export const HEIGHT = `${moduleName}/HEIGHT`

/**
 * Reducer
 * */
export default (height = 'auto', action) => {
  return action.type === HEIGHT ? action.height : height
}

/**
 * Action Creators
 * */
export const setHeight = (height) => {
  return {
    type: HEIGHT,
    height
  }
}

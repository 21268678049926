import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout-inner'
import { setLoading } from '../../redux/modules/loading'
import { gameImages, certificateImages } from '../../redux/config'
import UserPic from '../common/userpic'
import Snow from '../common/snow2'
import Packman from './packman'
import Modal from './modal'
import './style.scss'

class Game extends Component {
  state = {
    step: 1
  }


  render() {
    const { loading, setLoading, nextLoading, mobile, height } = this.props
    if (loading) return <Loader loading={loading} setLoading={() => setLoading('game')} images={gameImages} />
    const mob = mobile ? 'mob/' : ''
    if (this.state.step === 1) return this.modal
    return (
      <div className={`game game__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="top-bg" style={{backgroundImage: `url(/images/game/${mob}bg1.png)`}} />
        <Snow />
  
        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          <div className="layer game__bg2"><img src={`/images/game/${mob}bg2.png`} alt=""/></div>
          <div className="layer game__ellipse"><img src="/images/game/ellipse.png" alt=""/></div>
          <div className="layer game__area">
            <img src="/images/game/area1.png" alt=""/>
          </div>
        </div>

        <div className="spacer spacer-top">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          <div className="layer player">
            <img src="/images/player.png" alt=""/>
            <UserPic />
          </div>
          <div className="layer cloud"><img src={`/images/${mob}cloud.png`} alt=""/></div>
          <div className="layer logo"><img src="/images/logo-black.svg" alt=""/></div>
          <div className="layer logo2"><img src="/images/logo2.png" alt=""/></div>
          <div className="layer text"><Link to="/"><img src="/images/text.png" alt=""/></Link></div>
          <Link to="/plan" className="layer plan"><img src="/images/plan.png" alt=""/></Link>
        </div>
  
        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
       
          <Packman />
          <div className="layer game__text2"><img src={`/images/game/${mob}text2.png`} alt=""/></div>
          <div className="layer game__robot"><img src="/images/robot1.png" alt=""/></div>
        </div>

        {nextLoading && <Loader nextStepLoader={true} loading={nextLoading} setLoading={() => setLoading('certificate')} images={certificateImages} />}
      </div>
    )
  }

  get modal() {
    const { mobile, height } = this.props
    return (
      <div className={`eat eat__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          <Modal nextStep={this.nextStep} />
        </div>
      </div>
    )
  }

  nextStep = () => this.setState({ step: this.state.step + 1 })
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
    loading: state.loading.game,
    nextLoading: state.loading.certificate,
  }),
  { setLoading }
)(layout(Game))

import axios from 'axios'
import { api } from '../config'

/**
 * Constants
 * */
export const moduleName = 'auth'

export const CHECKED_SESSION = `${moduleName}/CHECKED_SESSION`
export const SIGN_IN = `${moduleName}/SIGN_IN`
export const LOADING = `${moduleName}/LOADING`
export const CREATE_PIC = `${moduleName}/CREATE_PIC`
export const CHANGE_GENDER = `${moduleName}/CHANGE_GENDER`
export const SAVE_PIC = `${moduleName}/SAVE_PIC`
export const OPEN = '_OPEN'
export const CLOSE = '_CLOSE'
export const PDF = 'PDF'
export const PDF_ERROR = 'PDF_ERROR'

/**
 * Reducer
 * */
const defaultState = {
  checkedSession: false,
  user: {
    name: '',
    gender: 'm',
    userpic: '',
    parents: '1',
    step: 1,
  },
  loading: true,
  pic: false
}

export default (state = defaultState, action) => {
  const { type, user, error, email, payload } = action

  switch (type) {

    case CHECKED_SESSION: 
      return { ...state, checkedSession: true }

    case SIGN_IN:
      return {...state, checkedSession: true, user }

    case LOADING:
      return {...state, loading: false }

    case CREATE_PIC + OPEN:
      return {...state, pic: true, user: {...state.user, gender: payload.gender} }
      
    case CREATE_PIC + CLOSE:
      if (payload && payload.userpic) {
        return {...state, pic: false, user: {...state.user, userpic: payload.userpic} }
      }
      return {...state, pic: false }
      
    case CHANGE_GENDER:
      return {...state,  user: {...state.user, gender: payload.gender} }
      
    case SAVE_PIC:
      return {...state, user: {...state.user, userpic: payload.userpic}}
      
    case PDF:
      return {...state, url: payload.url}

    default:
      return state
  }
}


/**
 * Action Creators
 * */

export const checkSession = (user) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-profile.php')
  if (data.success && !data.authorized) return dispatch({ type: CHECKED_SESSION })
  if (data.success && data.authorized) dispatch({ type: SIGN_IN, user: data })
}

export const setLevel = (user, step) => (dispatch) => {
  const updatedUser = {...user, step }
  dispatch({ type: SIGN_IN, user: updatedUser })
  const params = new FormData();
  params.append('step', step)
  const { data } = axios.post(api + 'user-registration.php', params)
}

export const signIn = (user) => async (dispatch) => {
  const params = new FormData();
  params.append('gender', user.gender)
  params.append('name', user.name)
  params.append('parents', user.parents)
  params.append('step', user.step)
  user.userpic.forEach((item) => params.append('userpic[]', item));

  const { data } = await axios.post(api + 'user-registration.php', params)
  console.log(data);

  if (data.success && data.authorized) dispatch({ type: SIGN_IN, user: {...user, id: data.id} })
}

export const getPdf = (hash) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-certificate.php', null, {params: {hash}})
  if (data.success && data.url) {
    dispatch({ type: PDF, payload: {url: data.url} })
  } 
}


export const setLoading = () => ({ type: LOADING })
export const changeGender = (gender) => ({ type: CHANGE_GENDER, payload: {gender} })

export const openCreatePic = (gender) => ({ type: CREATE_PIC + OPEN, payload: {gender} })
export const closeCreatePic = (userpic) => ({ type: CREATE_PIC + CLOSE, payload: {userpic} })
export const savePic = (userpic) => ({ type: SAVE_PIC, payload: {userpic} })

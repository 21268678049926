import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import 'slick-carousel/slick/slick.css'
import '../css/app.scss'

import PrivateRoute from './common/private-router'
import SignIn from './sign-in'
import MakeCandy from './make-candy'
import DrawCandy from './draw-candy/'

import Eat from './eat'
import Game from './game'
import Certificate from './certificate/'
import CheckMobile from './common/mobile'
import Plan from './plan'
import Pdf from './certificate/pdf'

import { closePlan } from '../redux/modules/loading'
import { checkSession } from '../redux/modules/sign-in'
import { setMobile } from '../redux/modules/mobile'
import { setHeight } from '../redux/modules/height'

class App extends Component {
  componentDidMount() {
    this.props.checkSession(localStorage.getItem('user'))
    this.props.setMobile(window.innerWidth <= 767)
    this.props.setHeight(window.innerHeight)
    window.addEventListener('resize', this._onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize)
  }

  render() {
    const {checked} = this.props
    if (!checked) return <div className="loading" />
    return (
      <div>
        <YMInitializer accounts={[51203048]} />
        <Switch>
          <Route path="/" exact={true}  component={this.getMain} />
          <PrivateRoute path="/make-candy" component={this.makeCandy} />
          <PrivateRoute path="/draw-candy" component={this.drawCandy} />
          <PrivateRoute path="/eat-candy" component={this.eat} />
          <PrivateRoute path="/game" component={this.game} />
          <PrivateRoute path="/certificate" component={this.certificate} />
          <PrivateRoute path="/plan" component={Plan} />
          <PrivateRoute path="/pdf" component={Pdf} />
        </Switch>
      </div>
    )
  }


  getMain = () => {
    if (this.props.user && this.props.plan) {
      this.props.closePlan()
      return <Redirect to={{pathname: '/plan', state: { from: '/' } }} />
    }
    return <SignIn />
  }

  makeCandy = ({ match }) => {
    if (this.props.step < 1) {
      this.props.closePlan()
      return <Redirect to="/" />
    }
    if (match.isExact) return <MakeCandy />
  }

  drawCandy = ({ match }) => {
    if (this.props.step < 2) return <Redirect to="/make-candy" />
    if (match.isExact) return <DrawCandy />
  }

  eat = ({ match }) => {
    if (this.props.step < 3) return <Redirect to="/draw-candy" />
    if (match.isExact) return <Eat />
  }

  game = ({ match }) => {
    if (this.props.step < 3) return <Redirect to="/eat-candy" />
    if (match.isExact) return <Game />
  }

  certificate = ({ match }) => {
    if (this.props.step < 4) return <Redirect to="/game" />
    if (match.isExact) return <Certificate />
  }

  _onResize = () => {
    const mobile = window.innerWidth <= 767
    const height = window.innerHeight

    if ((mobile && !this.props.mobile) || (!mobile && this.props.mobile)) {
      this.props.setMobile(mobile)
    }
    if (Math.abs(height - this.props.height) > 100) {
      this.props.setHeight(height)
    }
  }

}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
    step: state.auth.user.step,
    plan: state.loading.planOpen,
    user: state.auth.user,
    checked: state.auth.checkedSession
  }),
  { checkSession, closePlan, setMobile, setHeight }
)(App)

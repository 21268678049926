import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'

function UserPic() {
  const { userpic, gender } = useSelector((state) => state.auth.user)
  if (!userpic) return (
    <div className='userpic default'>
      <img src="/images/constructor/defaultpic.png" alt=""/>
    </div>
  )

  return (
    <div className={`userpic userpic_${gender}`}>
      <img src="/images/constructor/empty_pic.png" alt=""/>
      <div className="userpic__top" style={{backgroundImage: `url(/images/constructor/${gender}/${userpic[0]}.png)`}}></div>
      <div className={`userpic__bottom pic_${userpic[1]}`} style={{backgroundImage: `url(/images/constructor/${gender}/${userpic[1]}.png)`}}></div>
    </div>
  )
}

export default UserPic
import React from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { infoClose, modalOpen } from '../../redux/modules/cookie-info'
import { policyOpen } from '../../redux/modules/modal'

function CookieInfo() {
  const dispatch = useDispatch()
  const open = useSelector((state) => state.cookieInfo.info)
  if (!open) return null
  return (
    <div className="cookie-info">
      <div className="content">
        <div>
        Сайт<b>happy.x5.ru</b> использует Cookie, чтобы сделать пользование сайтом проще.
        <span className="link" onClick={() => dispatch(policyOpen())}>Что это значит?</span>
        </div>
        <div className="btn-border" onClick={() => dispatch(infoClose())}><span>Понятно!</span></div>
      </div>
    </div>
 
  )
}

export default CookieInfo


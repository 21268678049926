import React, { Fragment } from 'react'
// import Header from '../components/layout/header'
import Footer from '../components/layout/footer'
import CookieInfo from '../components/layout/cookie-info'

export default (OriginalComponent) =>
  class LayoutComponent extends React.Component {
    componentDidMount() {
      window.scrollTo(0,0)
    }
    render() {
      return (
        <Fragment>
          <div className="page">
            <CookieInfo />
            <OriginalComponent {...this.props} />
            <Footer />
            
          </div>
        </Fragment>
      )
    }
  }

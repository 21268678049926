import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { parents } from '../../redux/config'

class Modal extends Component {
  render() {
    const { mobile, user } = this.props
    return (
      <Fragment>
        <div className="draw-candy__overlay"></div>
        <div className="layer question">
          <img src="/images/make/bubble3.png" alt=""/>
          <div className="question__body">
              <span className="question__name">{user.name},</span>
              <span>а что {parents[user.parents].text2}</span>
              <span>нравится в работе?</span>
          </div>
        </div>
        <div className="layer draw-candy__robot2"><img src="/images/robot2.png" alt=""/></div>
        <Link to="/eat-candy" className="layer button button--3 button--question" ><img src="/images/button/14.svg" alt=""/></Link>
      </Fragment> 
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    user: state.auth.user
  }),
  {}
)(Modal)

import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { parents } from '../../redux/config'

class Modal extends Component {
  render() {
    const { mobile, user } = this.props
    return (
      <Fragment>
        <div className="draw-candy__overlay modal__overlay2 modal__overlay3 game__overlay"></div>
        <div className="layer game__bubble"><img src="/images/game/bubble.png" alt=""/></div>
        <div className="layer game__robot2"><img src="/images/robot1.png" alt=""/></div>
        <div className="layer button button--5 game__btn2" onClick={this.props.nextStep}><img src="/images/button/16.svg" alt=""/></div>

      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    user: state.auth.user
  }),
  {}
)(Modal)

import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { parents } from '../../redux/config'

class Modal extends Component {
  render() {
    const { mobile, user } = this.props
    return (
      <Fragment>
        <div className="eat__overlay"></div>
        <div className="layer question">
          <img src="/images/make/bubble3.png" alt=""/>
          <div className="question__body">
              <span className="question__name">{user.name},</span>
              <span>узнай у {parents[user.parents].text3}, сколько</span>
              <span>сотрудников работает в x5?</span>
          </div>
        </div>
        <div className="layer eat__robot2"><img src="/images/robot4.png" alt=""/></div>
        <Link to="/game" className="layer button button--3 button--question" ><img src="/images/button/14.svg" alt=""/></Link>
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    user: state.auth.user
  }),
  {}
)(Modal)

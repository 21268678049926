import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout-inner'
import { setLoading } from '../../redux/modules/loading'
import { makeCandyImages, drawImages, parents } from '../../redux/config'
import { setLevel } from '../../redux/modules/sign-in'

class Modal extends Component {
  render() {
    const { mobile } = this.props
    return (
      <Fragment>
        <div className="make__overlay modal__overlay2"></div>
        <div className="layer make__bubble1"><img src="/images/make/bubble1.png" alt=""/></div>
        <div className="layer make__robot2"><img src={mobile ? '/images/sign-in/mob/robot.png' : '/images/robot3.png'} alt=""/></div>
        <div className="layer button button--3" onClick={this.props.nextStep}><img src="/images/button/3.svg" alt=""/></div>
        {/*<div className="btn make__btn2" onClick={this.props.nextStep}><span>Клёво!</span> </div>*/}

      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  {}
)(Modal)

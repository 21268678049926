import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { parents } from '../../redux/config'

class Modal extends Component {
  render() {
    const { mobile, user, desctope } = this.props
    return (
      <Fragment>
        {mobile && <div className="make__overlay"></div>}
        <div className="layer question plan-question">
          <img src="/images/plan/bubble.png" alt=""/>
          <div className="question__body">
            <span className="question__name">{user.name}!</span>
          </div>
        </div>
        <div className="layer plan__robot2"><img src='/images/robot7.png' alt=""/></div>
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    user: state.auth.user
  }),
  {}
)(Modal)

// import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { Redirect, Route } from 'react-router-dom'
// import { authUserSelector } from '../../redux/modules/auth'

// class PrivateRoute extends Component {
//   render() {
//     const { user, component: Component, ...rest } = this.props
//     const storageUser = localStorage.getItem('user')

//     return <Route {...rest} render={(props) => (user ? <Component {...props} /> : <Redirect to="/sign-in" />)} />
//   }
// }

// export default connect(
//   (state) => ({
//     user: authUserSelector(state)
//   }),
//   {}
// )(PrivateRoute)




import React from "react"
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

function PrivateRoute({component:Component, ...rest}) {
  const user = useSelector((state) => state.auth.user)

  return <Route {...rest} render={(props) => (user.name ? <Component {...props} /> : <Redirect to="/" />)} />
}

export default PrivateRoute

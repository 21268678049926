import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setMobile } from '../../redux/modules/mobile'
import { setHeight } from '../../redux/modules/height'

class Mobile extends Component {
  componentDidMount() {
    this.props.setMobile(window.innerWidth <= 767)
    this.props.setHeight(window.innerHeight)
    window.addEventListener('resize', this._onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize)
  }

  render() {
    return null
  }

  _onResize = () => {
    const mobile = window.innerWidth <= 767
    const height = window.innerHeight

    if ((mobile && !this.props.mobile) || (!mobile && this.props.mobile)) {
      this.props.setMobile(mobile)
    }
    if (Math.abs(height - this.props.height) > 100) {
      this.props.setHeight(height)
    }
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height
  }),
  { setMobile, setHeight }
)(Mobile)

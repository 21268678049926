import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout-inner'
import { setLoading } from '../../redux/modules/loading'
import { certificateImages, parents } from '../../redux/config'
import { setLevel, getPdf } from '../../redux/modules/sign-in'
import UserPic from '../common/userpic'
import Snow from '../common/snow2'
import Modal from './modal'
import './style.scss'

class Certificate extends Component {
  state = {
    step: 1
  }


  render() {
    const { loading, setLoading, user, mobile, height } = this.props
    if (loading) return <Loader loading={loading} setLoading={() => setLoading('certificate')} images={certificateImages} />
    const mob = mobile ? 'mob/' : ''
    if (this.state.step === 1) return this.modal
    return (
      <div className={`cert cert__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="top-bg" style={{backgroundImage: `url(/images/cert/${mob}bg1.png)`}} />
        <Snow />

        <div className="spacer spacer-top">
          <img style={this.props.mobile ? {maxHeight: height} : {}} src={`/images/${mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile || <div className="layer cert__bg2"><img src={`/images/cert/${mob}bg2.png`} alt=""/></div>}
          <div className="layer player">
            <img src="/images/player.png" alt=""/>
            <UserPic />
          </div>
          <div className="layer cloud"><img src={`/images/${mob}cloud.png`} alt=""/></div>
          <div className="layer logo"><img src="/images/logo-black.svg" alt=""/></div>
          <div className="layer logo2"><img src="/images/logo2.png" alt=""/></div>
          <div className="layer text"><Link to="/"><img src="/images/text.png" alt=""/></Link></div>
          <Link to="/plan" className="layer plan"><img src="/images/plan.png" alt=""/></Link>
        </div>

        <div className="spacer spacer-middle">
          <img style={mobile ? {maxHeight: height} : {}} src={`/images/${mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
     
          
          <div className="layer cert__ellipse"><img src="/images/cert/ellipse.png" alt=""/></div>
          <div className="layer cert__area"><img src="/images/cert/area.png" alt=""/></div>
          <div className="layer cert__chocolate"><img src="/images/cert/chocolate.png" alt=""/></div>
          <div className="layer cert__cert"><img src="/images/cert/cert.png" alt=""/></div>
          <div className="layer cert__text2"><img src={`/images/cert/${mob}text.png`} alt=""/></div>
          {this.state.step > 1 && <div className="layer cert__robot"><img src={mobile ? "/images/robot1.png" : "/images/robot6.png"} alt=""/></div>}
          {this.state.step === 2 && <Link to="/pdf" className="layer button button--12" ><img src="/images/button/12.svg" alt=""/></Link>}

          {this.state.step === 1 && <Modal nextStep={this.nextStep} />}

        </div>

      </div>
    )
  }

  get modal() {
    const { mobile, height } = this.props
    return (
      <div className={`cert cert__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="spacer spacer-middle">
          <img style={mobile ? {maxHeight: height} : {}} src={`/images/${mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          <Modal nextStep={this.nextStep} />
        </div>
      </div>
    )
  }

  nextStep = () => {
    if (this.props.user.step < 6) this.props.setLevel(this.props.user, 6 ) 
    this.setState({ step: this.state.step + 1 })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    loading: state.loading.certificate,
    user: state.auth.user
  }),
  { setLoading, setLevel, getPdf }
)(layout(Certificate))

import React from 'react'
import { connect } from 'react-redux'

function Footer({black, mobile}) {
  const _black = black ? '_black' : ''
  return (
    <div className="footer">
      <div className="content">
          <div className="footer__icons">
            <a className="footer__ico footer__ico1" href="https://www.x5.ru/ru" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p1${_black}.svg`} alt="" />
            </a>
            <a className="footer__ico footer__ico2" href="https://5ka.ru/" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p2${_black}.svg`} alt="" />
            </a>
            <a className="footer__ico footer__ico3" href="https://karusel.ru/" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p3${_black}.svg`} alt="" />
            </a>
            <a className="footer__ico footer__ico4" href="https://www.perekrestok.ru/" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p4${_black}.svg`} alt="" />
            </a>
            <a className="footer__ico footer__ico5" href="https://www.x5.ru/ru/" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p5${_black}.svg`} alt="" />
            </a>
          </div>
          <div className="footer__text">
            Возникла проблема с порталом? {mobile && <br/>} <a href="mailto:ny@happy.x5.ru" target="_blank"> Напишите нам </a> , и мы всё исправим.
            <br/>
            © 2020 «Х5 Retail Group». Все права защищены.
          </div>
          <div>
            Сделано в{' '}
            <a href="http://www.amio.ru/" target="_blank" rel="noopener noreferrer">
              A M I O
            </a>
          </div>
      </div>
    </div>
  )
}


export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  {}
)(Footer)

import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

function Select({value, name, valid, setFieldValid, change, options, trigger, cl, callback}) {
  const dispatch = useDispatch()
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) setFieldValid(name, isValidField())
    if (!mounted.current) mounted.current = true
  }, [value])

  const isValidField = () => {
    if (value === '') return false
    return true
  }

  const getClassName = () => {
    const validCl = valid || (!value && !trigger) ? 'valid' : 'error'
    return 'input-field select-field ' + validCl + (cl ? (' '+ cl) : '')
  }

  const handleChange = (ev) => {
    change(ev)
    if (callback) dispatch(callback(ev.target.value))
  }

  return (
    <div className={getClassName()}>
      <select name={name} value={value} onChange={handleChange}>
        {options.map((el, i) => <option key={i} value={el.value}>{el.label}</option>)}
      </select>
    </div>
  )
}

Select.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,

  setFieldValid: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

export default Select

import React from "react"
import layout from '../../../decorators/layout-black'
import { useDispatch, useSelector } from 'react-redux'
import { policyClose } from '../../../redux/modules/modal'

function PolicyBody() {
  const mobile = useSelector((state) => state.mobile)
  const dispatch = useDispatch()
  return (
    <div className="content policy-content">
      <div className="policy-title">Что такое coockie?<br/> И почему сайты предупреждают о них?</div>
      <p>Если простыми словами, то сайты, которые вы посещаете, делают у себя пометки, что к ним зашел такой-то пользователь сети интернет (вы). В следующий раз, когда вы снова к ним зайдете, они вас узнают и могут показать ваш аккаунт, в который вы ранее входили, чтобы вам не входить каждый раз (в том числе, после первого входа).</p>
      <p>Именно поэтому, когда вы зайдете на этот сайт завтра, то он вас узнает, и вам не надо будет выполнять уже пройденные задания и заполнять данные повторно</p>
      <p>А предупреждают, потому что это сбор пользовательских данных. В большинстве стран мира такой сбор регулируется законом, один из которых – оповещать пользователя сайта, что сайт собирает и хранит эти самые кукис.</p>
      {mobile && <div className="modal-policy-mobile-btn"><div className="btn3" onClick={() => dispatch(policyClose())}><span>Вернуться на главную</span></div></div>}
    </div>
  )
}

export default layout(PolicyBody)
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout-inner'
import { setLoading, getPage } from '../../redux/modules/loading'
import { eatImages, gameImages, parents } from '../../redux/config'
import { setLevel } from '../../redux/modules/sign-in'
import UserPic from '../common/userpic'
import Snow from '../common/snow2'
import Modal from './modal'
import Modal2 from './modal2'
import Modal3 from './modal3'
import './style.scss'

class Eat extends Component {
  state = {
    step: 1,
    player1: 0,
    player2: 0,
    finish: false,
    init: true
  }

  tid = null

  i1 = null
  i2 = null

  componentDidUpdate(prevProps, prevState) {
    if (prevState.player2 !== this.state.player2 && this.state.player2 === 3) {
      this.nextStep()
    }
    if (this.state.step === 3 && prevState.step !== this.state.step) {
      if (this.props.user.step < 4) this.props.setLevel(this.props.user, 4 ) 
      clearInterval(this.i1)
      // this.tid = setTimeout(() => this.props.getPage('/game'), 6000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.id)
    clearInterval(this.i1)
    clearInterval(this.i2)
    clearTimeout(this.tid)
  }


  render() {
    const { loading, setLoading, user, nextLoading, mobile, height } = this.props
    const { player1, player2 } = this.state
    if (loading) return <Loader loading={loading} setLoading={() => setLoading('eat')} images={eatImages} />
    const mob = mobile ? 'mob/' : ''
    if (this.state.step === 1) return this.modal
    return (
      <div className={`eat eat__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="top-bg" style={{backgroundImage: `url(/images/eat/${mob}bg1.png)`}} />
        <Snow />
        <div className="spacer spacer-top">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          <div className="layer eat__bg2"><img src={`/images/eat/${mob}bg2.png`} alt=""/></div>
          <div className="layer player">
            <img src="/images/player.png" alt=""/>
            <UserPic />
          </div>
          <div className="layer cloud"><img src={`/images/${mob}cloud.png`} alt=""/></div>
          <div className="layer logo"><img src="/images/logo-black.svg" alt=""/></div>
          <div className="layer logo2"><img src="/images/logo2.png" alt=""/></div>
          <div className="layer text"><Link to="/"><img src="/images/text.png" alt=""/></Link></div>
          <Link to="/plan" className="layer plan"><img src="/images/plan.png" alt=""/></Link>
        </div>

        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          <div className="layer eat__ellipse"><img src="/images/eat/ellipse.png" alt=""/></div>

          <div className="layer eat__candy" onClick={this.handleClick}><img src="/images/eat/candy.png" alt=""/></div>
          {player2 > 0  && <div className="layer eat__piece-player"><img src="/images/eat/pieces/player/1.png" alt=""/></div>}
          {player2 > 1  && <div className="layer eat__piece-player"><img src="/images/eat/pieces/player/2.png" alt=""/></div>}
          {player2 > 2  && <div className="layer eat__piece-player"><img src="/images/eat/pieces/player/3.png" alt=""/></div>}
          {player1 > 0  && <div className="layer eat__piece-tester"><img src="/images/eat/pieces/tester/1.png" alt=""/></div>}
          {player1 > 1  && <div className="layer eat__piece-tester"><img src="/images/eat/pieces/tester/2.png" alt=""/></div>}
          {player1 > 2  && <div className="layer eat__piece-tester"><img src="/images/eat/pieces/tester/3.png" alt=""/></div>}
          <div className="layer eat__tester"><img src={`/images/eat/${mob}tester.png`} alt=""/></div>
          <div className="layer eat__player2"><img src={`/images/eat/${mob}player${user.gender}.png`} alt=""/></div>
          <div className="layer eat__count eat__title eat__title1">Штатный<br/>тестировщик</div>
          <div className="layer eat__count eat__title eat__title2">{user.name}</div>
          <div className="layer eat__count eat__count1">{player1} <span>укуса</span></div>
          <div className="layer eat__count eat__count2">{player2} <span>укуса</span></div>

          {(player1 === 0 && player2 === 0 && this.state.init) && <div className="layer button button--8" onClick={this.start}><img src="/images/button/8.svg" alt=""/></div>}
          {/*player1 === 0 && player2 === 0 && this.state.init && <div className="btn eat__btn" onClick={this.start}><span>Старт!</span> </div>*/}
          {/*player1 === 3 && <div className="btn eat__btn" onClick={this.start}><span>Еще раз!</span> </div>*/}

          <div className="layer eat__text2"><img src={`/images/eat/${mob}text.png`} alt=""/></div>
          {this.state.step > 1 && this.state.step < 3 && player1 < 3  && <div className="layer eat__robot"><img src="/images/robot1.png" alt=""/></div>}

          {player1 === 3 && <Modal3 nextStep={this.clear} />}
          {this.state.step === 3 && <Fragment>
            <div className="layer eat__bubble2"><img src="/images/eat/bubble4.png" alt=""/></div>
            <div className="layer button button--3 button--win" onClick={this.nextStep} ><img src="/images/button/17.svg" alt=""/></div>
          </Fragment>}
          {this.state.step === 4 && <Modal2 />}
        </div>

        {nextLoading && <Loader nextStepLoader={true} loading={nextLoading} setLoading={() => setLoading('game')} images={gameImages} />}
      </div>
    )
  }

  get modal() {
    const { mobile, height } = this.props
    return (
      <div className={`eat eat__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          <Modal nextStep={this.nextStep} />
        </div>
      </div>
    )
  }

  handleClick = () => {
    if (this.state.init) return false
    if (this.state.player2 < 3 && this.state.player1 < 3) this.setState({player2: this.state.player2 + 1})
  }

  clear = () => this.setState({player1: 0, player2: 0, finish: false, init: true})

  start = () => {
    this.setState({player1: 0, player2: 0, finish: false, init: false})
    let timer = 0
    this.i1 = setInterval(() => {
      if (timer < 3) this.setState({player1: this.state.player1 + 1})
      else clearInterval(this.i1)
      timer++
    }, 800) 
  }

  nextStep = () => this.setState({ step: this.state.step + 1 })
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
    loading: state.loading.eat,
    nextLoading: state.loading.game,
    user: state.auth.user
  }),
  { setLoading, setLevel, getPage }
)(layout(Eat))

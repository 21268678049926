import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout-inner'
import { setLoading, getBack, getPage, closePlan } from '../../redux/modules/loading'
import { planImages, signInImages } from '../../redux/config'
import UserPic from '../common/userpic'
import Snow from '../common/snow2'
import Modal from './modal'
import './style.scss'

class Plan extends Component {
  state = {
    modal: true
  }
  id = null

  componentDidUpdate(prevProps, prevState) {
    const hello = this.props.location && this.props.location.state && this.props.location.state.from
    if (hello && this.state.modal ) {
      this.id = setTimeout(() => this.setState({modal: false}), 2800)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.id )
  }

  render() {
    const { loading, nextLoading, setLoading, user, mobile, height } = this.props
    const mob = mobile ? 'mob/' : ''

    const hello = this.props.location && this.props.location.state && this.props.location.state.from

    if (loading) return <Loader loading={loading} setLoading={() => setLoading('plan')} images={planImages} />
    if (hello && this.state.modal && mobile) return this.modal
    return (
      <div className="pl">
        <div className="top-bg" style={{backgroundImage: `url(/images/make/${mob}bg1.png)`}} />
        <Snow />
        <div className="spacer spacer-top">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile || <div className="layer make__bg2"><img src="/images/make/bg2.png" alt=""/></div>}
          <div className="layer cloud"><img src={`/images/${mob}cloud.png`} alt=""/></div>
          <div className="layer logo"><img src="/images/logo-black.svg" alt=""/></div>
          <div className="layer logo2"><img src="/images/logo2.png" alt=""/></div>
          <div className="layer text"><div onClick={this.handleGetMain}><img src="/images/text.png" alt=""/></div></div>
          <div className="layer plan"><img src="/images/plan.png" alt=""/></div>
        </div>
        <div className="spacer spacer-middle">
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          {mobile && <div className="layer make__robot"><img src="/images/robot1.png" alt=""/></div>}
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          <div className="layer pl__ellipse"><img src={`/images/plan/${mob}ellipse.png`} alt=""/></div>
          <div className="layer pl__path"><img src={`/images/plan/${mob}path.png`} alt=""/></div>
          <div className="layer pl__point pl__point1" onClick={() => this.handleGetPage(1)}><img src="/images/plan/point.png" alt=""/></div>
          <div className="layer pl__point pl__i1" onClick={() => this.handleGetPage(1)}><img src="/images/plan/i1.png" alt=""/></div>
          {user.step <=  1 && <div className="layer pl__q pl__q1"><img src="/images/plan/q.png" alt=""/></div>}
          {user.step > 1 && <div className="layer pl__ok pl__ok1"><img src="/images/plan/ok.png" alt=""/></div>}

          <div className="layer pl__point pl__i2" onClick={() => this.handleGetPage(2)}><img src="/images/plan/i2.png" alt=""/></div>
          <div className="layer pl__point pl__point2" onClick={() => this.handleGetPage(2)}><img src="/images/plan/point.png" alt=""/></div>
          {user.step <=  2 && <div className="layer pl__q pl__q2"><img src="/images/plan/q.png" alt=""/></div>}
          {user.step > 2 && <div className="layer pl__ok pl__ok2"><img src="/images/plan/ok.png" alt=""/></div>}

          <div className="layer pl__point pl__i3" onClick={() => this.handleGetPage(3)}><img src="/images/plan/i3.png" alt=""/></div>
          <div className="layer pl__point pl__point3" onClick={() => this.handleGetPage(3)}><img src="/images/plan/point.png" alt=""/></div>
          {user.step <=  3 && <div className="layer pl__q pl__q3"><img src="/images/plan/q.png" alt=""/></div>}
          {user.step > 3 && <div className="layer pl__ok pl__ok3"><img src="/images/plan/ok.png" alt=""/></div>}

          <div className="layer pl__point pl__point4" onClick={() => this.handleGetPage(4)}><img src="/images/plan/point.png" alt=""/></div>
          <div className="layer pl__point pl__i4" onClick={() => this.handleGetPage(4)}><img src="/images/plan/i4.png" alt=""/></div>
          {user.step <=  4 && <div className="layer pl__q pl__q4"><img src="/images/plan/q.png" alt=""/></div>}
          {user.step > 4 && <div className="layer pl__ok pl__ok4"><img src="/images/plan/ok.png" alt=""/></div>}

          <div className="layer pl__point pl__i5" onClick={() => this.handleGetPage(5)}><img src="/images/plan/i5.png" alt=""/></div>
          <div className="layer pl__point pl__point5" onClick={() => this.handleGetPage(5)}><img src="/images/plan/point.png" alt=""/></div>
          {user.step <=  5 && <div className="layer pl__q pl__q5"><img src="/images/plan/q.png" alt=""/></div>}
          {user.step > 5 && <div className="layer pl__ok pl__ok5"><img src="/images/plan/ok.png" alt=""/></div>}

          <div className="layer pl__text"><img src="/images/plan/text.png" alt=""/></div>
          <div className="layer pl__point pl__t1" onClick={() => this.handleGetPage(1)}><img src="/images/plan/t1.png" alt=""/></div>
          <div className="layer pl__point pl__t2" onClick={() => this.handleGetPage(2)}><img src="/images/plan/t2.png" alt=""/></div>
          <div className="layer pl__point pl__t3" onClick={() => this.handleGetPage(3)}><img src="/images/plan/t3.png" alt=""/></div>
          <div className="layer pl__point pl__t4" onClick={() => this.handleGetPage(4)}><img src="/images/plan/t4.png" alt=""/></div>
          <div className="layer pl__point pl__t5" onClick={() => this.handleGetPage(5)}><img src="/images/plan/t5.png" alt=""/></div>
          <div className="layer button button--13" onClick={this.handleClose}><img src="/images/button/13.svg" alt=""/></div>
          {hello && !mobile && <Modal />}
        </div>
        {nextLoading && <Loader nextStepLoader={true} loading={nextLoading} setLoading={() => setLoading('signin')} images={signInImages} />}
      </div>
    )
  }

  get modal() {
    const { mobile, height } = this.props
    return (
      <div className={`make make__step-1`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          <Modal nextStep={this.nextStep} />
        </div>
      </div>
    )
  }

  handleGetPage = (step) => {
    const map = {
      1: '/make-candy',
      2: '/draw-candy',
      3: '/eat-candy',
      4: '/game',
      5: '/certificate',
    }

    if (this.props.user.step > step - 1) this.props.getPage(map[step])
  }

  handleGetMain = () => {
    this.props.closePlan()
    this.props.getPage('/')
  }

  handleClose = () => {
    if (this.props.location && this.props.location.state && this.props.location.state.from) {
      this.props.closePlan()
      this.props.getPage(this.props.location.state.from)
    } else {
      this.props.getBack()
    }
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    loading: state.loading.plan,
    nextLoading: state.loading.signin,
    user: state.auth.user,
    height: state.height,
  }),
  { setLoading, getBack, getPage, closePlan }
)(layout(Plan))

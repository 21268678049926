import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout-inner'
import { setLoading } from '../../redux/modules/loading'
import { makeCandyImages, drawImages, parents } from '../../redux/config'
import { setLevel } from '../../redux/modules/sign-in'

class Modal extends Component {
  render() {
    const { mobile } = this.props
    return (
      <Fragment>
        <div className="eat__overlay"></div>
        <div className="layer eat__bubble"><img src="/images/eat/bubble2.png" alt=""/></div>
        <div className="layer eat__robot2"><img src="/images/robot4.png" alt=""/></div>
        <div className="layer button button--7" onClick={this.props.nextStep}><img src="/images/button/7.svg" alt=""/></div>
        {/*<div className="btn eat__btn2" onClick={this.props.nextStep}><span>Съесть!</span> </div>*/}
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  {}
)(Modal)

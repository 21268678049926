import React from 'react'
import Header from '../components/layout/header'
import Footer from '../components/layout/footer'

export default (OriginalComponent) =>
  class LayoutComponent extends React.Component {
    // componentDidMount() {
    //   window.scrollTo(0,0)
    // }
    render() {
      return (
        <div className="page page-black">
          <Header />
          <OriginalComponent {...this.props} />
          <Footer black={true} />
        </div>
      )
    }
  }

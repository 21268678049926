/**
 * Constants
 * */
export const moduleName = 'cookieInfo'

export const MODAL = `${moduleName}/MODAL`
export const INFO = `${moduleName}/INFO`
export const OPEN = `_OPEN`
export const CLOSE = `_CLOSE`

/**
 * Reducer
 * */
const defaultState = {
  info: true,
  modal: false
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case MODAL + OPEN:
      return {...state, modal: true }
      
    case MODAL + CLOSE:
      return {...state, modal: false }
      
    case INFO + CLOSE:
      return {...state, info: false }

    default:
      return state
  }
}


/**
 * Action Creators
 * */

export const modalOpen = () => ({ type: MODAL + OPEN })
export const modalClose = () => ({ type: MODAL + OPEN })
export const infoClose = () => ({ type: INFO + CLOSE })

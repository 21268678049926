import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'

function UserPic({face, body}) {
  const { gender } = useSelector((state) => state.auth.user)
  return (
    <div className={`userpic userpic_${gender}`}>
      <img src="/images/constructor/empty_pic.png" alt=""/>
      <div className="userpic__top" style={{backgroundImage: `url(/images/constructor/${gender}/${face}.png)`}}></div>
      <div className={`userpic__bottom pic_${body}`} style={{backgroundImage: `url(/images/constructor/${gender}/${body}.png)`}}></div>
    </div>
  )
}

export default UserPic
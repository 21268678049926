import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout-inner'
import { setLoading, getPage } from '../../redux/modules/loading'
import { makeCandyImages, drawImages, parents } from '../../redux/config'
import { setLevel } from '../../redux/modules/sign-in'
import UserPic from '../common/userpic'
import Snow from '../common/snow2'
import Modal from './modal'
import Modal2 from './modal2'
import './style.scss'

class MakeCandy extends Component {
  state = {
    question: false,
    showBubble: true,
    step: 1,
    candy: null,
  }

  id = null
  tid = null

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step === 3 && prevState.step !== this.state.step) {
      clearInterval(this.id)
      clearTimeout(this.tid)

      const {candy} = this.state
      this.id = setInterval(() => {
        let random = Math.floor(Math.random() * 6) + 1;
        this.setCandy(random)
      }, 200)
      this.tid = setTimeout(() => {
        this.prevStep()
        clearInterval(this.id)
      }, 2000)
    }
    if (this.state.question && this.state.question !== prevState.question) {
      if (this.props.user.step < 2) {
        this.props.setLevel(this.props.user, 2 )
      }
      // this.tid = setTimeout(() => this.props.getPage('/draw-candy'), 6000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.id)
    clearTimeout(this.tid)
  }

  setCandy = (candy) => this.setState({ candy })

  render() {
    const { loading, nextLoading, setLoading, user, mobile, height } = this.props
    const mob = mobile ? 'mob/' : ''

    if (loading) return <Loader loading={loading} setLoading={() => setLoading('candy')} images={makeCandyImages} />
    if (this.state.step === 1 ) return this.modal
    return (
      <div className={`make make__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="top-bg" style={{backgroundImage: `url(/images/make/${mob}bg1.png)`}} />
        <Snow />
        <div className="spacer spacer-top">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile || <div className="layer make__bg2"><img src="/images/make/bg2.png" alt=""/></div>}
          <div className="layer player">
            <img src="/images/player.png" alt=""/>
            <UserPic />
          </div>
          <div className="layer cloud"><img src={`/images/${mob}cloud.png`} alt=""/></div>
          <div className="layer logo"><img src="/images/logo-black.svg" alt=""/></div>
          <div className="layer logo2"><img src="/images/logo2.png" alt=""/></div>
          <div className="layer text"><Link to="/"><img src="/images/text.png" alt=""/></Link></div>
          <Link to="/plan" className="layer plan"><img src="/images/plan.png" alt=""/></Link>
        </div>

        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}

          <div className="layer make__ellipse"><img src="/images/make/ellipse.png" alt=""/></div>
          <div className="layer make__text"><img src={`/images/make/${mob}text.png`} alt=""/></div>
          {!this.state.question && <div className="layer make__robot"><img src="/images/robot1.png" alt=""/></div>}
          <div className="layer make__cloud2"><img src={`/images/make/cloud.png`} alt=""/></div>
          <div className="layer make__cloud3"><img src="/images/make/cloud2.png" alt=""/></div>
          <div className={`layer make__gadget ${this.state.step > 2 ? 'make__gadget--animated' : ''}`}><img src="/images/make/gadget.png" alt=""/></div>
          {this.state.candy && <div className="layer make__small-candy"><img src={`/images/make/small/${this.state.candy}.png`} alt=""/></div>}
          {this.state.step === 2 && this.state.candy && <div className="layer make__large-candy"><img src={`/images/make/large/${this.state.candy}.png`} alt=""/></div>}

          {this.state.step <= 2 && <div className="layer make__control-lever" onClick={this.nextStep}><img src="/images/make/control-lever.png" alt=""/></div>}
          {this.state.step > 2 && <div className="layer make__control-lever2" onClick={this.nextStep}><img src="/images/make/control-lever2.png" alt=""/></div>}
          {this.state.step === 2 && this.state.showBubble && <div className="layer make__bubble2" onClick={this.nextStep}><img src="/images/make/bubble2.png" alt=""/></div>}
         

          
          {this.state.step === 2 && this.state.candy && 
            <div className="layer button button--4" onClick={this.getQuestion}><img src="/images/button/4.svg" alt=""/></div>
          }
          {/*this.state.step === 2 && this.state.candy && <div onClick={this.getQuestion} className="btn make__btn" >
            <span>Сделать фантик</span>
          </div>*/}

          {this.state.question && <Modal2 />}

        </div>

        {nextLoading && <Loader nextStepLoader={true} loading={nextLoading} setLoading={() => setLoading('draw')} images={drawImages} />}
      </div>
    )
  }

  get modal() {
    const { mobile, height } = this.props
    return (
      <div className={`make make__step-${this.state.step}`} style={mobile ? {minHeight: height + 'px'} : {}}>
        <div className="spacer spacer-middle">
          <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/${this.props.mobile ? 'mob' : 'spacer'}.png`} alt=""/>
          {mobile && <div className="layer footer-candy"><img src="/images/footer.png" alt=""/></div>}
          <Modal nextStep={this.nextStep} />
        </div>
      </div>
    )
  }

  getQuestion = () => this.setState({question: true})
  nextStep = () => this.setState({ step: this.state.step + 1 })
  prevStep = () => this.setState({ step: this.state.step - 1, showBubble: false })
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    loading: state.loading.candy,
    nextLoading: state.loading.draw,
    user: state.auth.user,
    height: state.height
  }),
  { setLoading, setLevel, getPage }
)(layout(MakeCandy))

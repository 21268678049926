export const api = window.location.hostname === 'localhost' ? 'http://1june2020.multicontent.ru/ajax/' : '/ajax/'
export const api2 = window.location.hostname === 'localhost' ? 'http://1june2020.multicontent.ru' : ''

export const signInImages = [
'/images/spacer.png',
'/images/spacer-main.png',
 '/images/robot1.png',
 '/images/button/1.svg',
 '/images/button/2.svg',
 '/images/button/3.svg',
 '/images/button/4.svg',
 '/images/button/5.svg',
 '/images/button/6.svg',
 '/images/button/7.svg',
 '/images/button/8.svg',
 '/images/button/9.svg',
 '/images/button/10.svg',
 '/images/button/11.svg',
 '/images/button/12.svg',
 '/images/button/13.svg',
 '/images/button/14.svg',
 '/images/button/15.svg',
 '/images/button/16.svg',
 '/images/button/17.svg',
 '/images/mob/robot2.png',
 '/images/mob/cloud.png',


  '/images/sign-in/bubble2.png',
  '/images/sign-in/accepted.png',
  '/images/sign-in/bg.png',
  '/images/sign-in/logo.svg',
  '/images/sign-in/form.png',
  '/images/sign-in/bg2.png',
  '/images/sign-in/robot.png',
  '/images/sign-in/guy.png',
  '/images/sign-in/field2.svg',
  '/images/sign-in/candy2.png',
  '/images/sign-in/field1.svg',
  '/images/sign-in/bubble.png',
  '/images/sign-in/select-arrow1.svg',
  '/images/sign-in/photo-border.png',
  '/images/sign-in/candy.png',
  '/images/sign-in/select-arrow2.svg',
  '/images/sign-in/cake.png',

  '/images/sign-in/mob/bubble.png',
  '/images/sign-in/mob/form.png',
  '/images/sign-in/mob/candy.png',
  '/images/sign-in/mob/robot.png',
  '/images/sign-in/mob/bg2.png',
  '/images/sign-in/mob/bgbg.png',

  '/images/sign-in/candy/candy.png',
  '/images/sign-in/candy/empty.png',
  '/images/sign-in/candy/11.png',
  '/images/sign-in/candy/10.png',
  '/images/sign-in/candy/9.png',
  '/images/sign-in/candy/8.png',
  '/images/sign-in/candy/7.png',
  '/images/sign-in/candy/6.png',
  '/images/sign-in/candy/5.png',
  '/images/sign-in/candy/4.png',
  '/images/sign-in/candy/3.png',
  '/images/sign-in/candy/2.png',
  '/images/sign-in/candy/1.png',

]
export const makeCandyImages = [
  '/images/spacer.png',
  '/images/player.png',
  '/images/cloud.png',
  '/images/logo-black.svg',
  '/images/logo2.png',
  '/images/text.png',
  '/images/robot1.png',
  '/images/robot3.png',
  '/images/make/text.png',
  '/images/make/bg1.png',
  '/images/make/ellipse.png',
  '/images/make/bg2.png',
  '/images/make/gadget.png',
  '/images/make/bubble2.png',
  '/images/make/bubble1.png',
  '/images/make/control-lever.png',
  '/images/make/control-lever2.png',
  '/images/make/cloud2.png',
  '/images/make/cloud.png',
  '/images/make/bubble3.png',
  '/images/make/mob/bg1.png',
  '/images/make/mob/text.png',
  '/images/make/large/6.png',
  '/images/make/large/5.png',
  '/images/make/large/4.png',
  '/images/make/large/1.png',
  '/images/make/large/3.png',
  '/images/make/large/2.png',
  '/images/make/small/6.png',
  '/images/make/small/5.png',
  '/images/make/small/4.png',
  '/images/make/small/1.png',
  '/images/make/small/3.png',
  '/images/make/small/2.png',
]
export const drawImages = [
'/images/spacer.png',
 '/images/player.png',
 '/images/cloud.png',
 '/images/logo-black.svg',
 '/images/logo2.png',
 '/images/text.png',
 '/images/robot2.png',
  '/images/draw-candy/bg1.png',
 '/images/draw-candy/bg2.png',
 '/images/draw-candy/ellipse.png',
 '/images/draw-candy/stars.png',
 '/images/draw-candy/text2.png',
 '/images/draw-candy/bubble.png',
 '/images/draw-candy/gift/default.png',
 '/images/draw-candy/gift/1.png',
 '/images/draw-candy/gift/2.png',
 '/images/draw-candy/gift/3.png',
  '/images/draw-candy/mob/text2.png',
  '/images/draw-candy/mob/stars.png',
  '/images/draw-candy/mob/bg1.png',
  '/images/draw-candy/mob/bg2.png',
]
export const eatImages = [
'/images/eat/bubble2.png',
'/images/eat/bubble4.png',
'/images/eat/bubble3.png',
'/images/button/17.svg',
'/images/spacer.png',
 '/images/player.png',
 '/images/cloud.png',
 '/images/logo-black.svg',
 '/images/logo2.png',
 '/images/text.png',
 '/images/robot1.png',
  '/images/eat/bg1.png',
 '/images/eat/ellipse.png',
 '/images/eat/bubble.png',
 '/images/eat/playerm.png',
 '/images/eat/playerf.png',
 '/images/eat/tester.png',
 '/images/eat/piece.png',
 '/images/eat/candy.png',
 '/images/eat/text.png',
 '/images/eat/bg1.png',
 '/images/eat/bg2.png',
 '/images/eat/ellipse.png',
 '/images/robot4.png',
  '/images/eat/mob/text.png',
  '/images/eat/mob/tester.png',
  '/images/eat/mob/playerf.png',
  '/images/eat/mob/playerm.png',
  '/images/eat/mob/bg1.png',
  '/images/eat/mob/bg2.png',
]
export const certificateImages = [
'/images/spacer.png',
 '/images/player.png',
 '/images/cloud.png',
 '/images/logo-black.svg',
 '/images/logo2.png',
 '/images/text.png',
 '/images/robot1.png',
 '/images/cert/bg2.png',
 '/images/cert/chocolate.png',
 '/images/cert/cert.png',
 '/images/cert/text.png',
 '/images/cert/bg1.png',
 '/images/cert/ellipse.png',
 '/images/cert/bubble.png',
 '/images/robot6.png', 
  '/images/cert/mob/bg2.png',
  '/images/cert/mob/bg1.png',
  '/images/cert/mob/text.png',
]

export const planImages = [
'/images/spacer.png',
'/images/spacer.png',
 '/images/robot1.png',
 '/images/plan/q.png',
 '/images/plan/ok.png',
 '/images/plan/i1.png',
 '/images/plan/i2.png',
 '/images/plan/i3.png',
 '/images/plan/i4.png',
 '/images/plan/i5.png',
 '/images/plan/t1.png',
 '/images/plan/t2.png',
 '/images/plan/t3.png',
 '/images/plan/t4.png',
 '/images/plan/t5.png',
 '/images/plan/text.png',
 '/images/plan/point.png',
 '/images/plan/path.png',
 '/images/plan/ellipse.png',
 '/images/plan/mob/path.png',
 '/images/plan/mob/ellipse.png',
 ]

export const gameImages = [
  '/images/spacer.png',
   '/images/player.png',
   '/images/cloud.png',
   '/images/logo-black.svg',
   '/images/logo2.png',
   '/images/text.png',
   '/images/robot1.png',
   '/images/btn/6.svg',
  '/images/game/bg1.png',
  '/images/game/bg2.png',
  '/images/game/ellipse.png',
  '/images/game/player.png',
  '/images/game/text2.png',
  '/images/game/heart.png',
  '/images/game/2.png',
  '/images/game/1.png',
  '/images/game/pepper.png',
  '/images/game/area1.png',
  '/images/game/candy.png',
  '/images/game/area-space.png',
  '/images/game/game-over.svg',
  '/images/game/grandma.png',
  '/images/game/stars.png',
  '/images/game/starts.png',
  '/images/game/num.png',
  '/images/game/samovar.png',
  '/images/game/ellipse2.png',
  '/images/game/area2.png',
  '/images/game/square-spacer.png',
  '/images/game/board.png',
  '/images/game/walls.png',
  '/images/game/h5.png',
  '/images/game/h4.png',
  '/images/game/h3.png',
  '/images/game/h1.png',
  '/images/game/h2.png',
  '/images/game/h8.png',
  '/images/game/h7.png',
  '/images/game/h6.png',
  '/images/game/bubble.png',
  '/images/game/game-over-arrow.png',
  '/images/game/game-over.png',
  '/images/game/candy2.png',
  '/images/game/numbg.png',
  '/images/game/h11.png',
  '/images/game/h.png',
  '/images/game/h10.png',
  '/images/game/h9.png',
  '/images/game/bubble2.png',
  '/images/game/images/x5.png',
  '/images/game/images/3.png',
  '/images/game/images/2.png',
  '/images/game/images/1.png',
  '/images/game/mob/walls.png',
  '/images/game/mob/bg2.png',
  '/images/game/mob/bg1.png',
  '/images/game/mob/samovar.png',
  '/images/game/mob/grandma.png',
  '/images/game/mob/text2.png',
  '/images/game/mob/left-arrow.png',
  '/images/game/mob/right-arrow.png',
 ]


export const parents = {
  1: {label: 'Мама', text: 'твоя мама', text2: 'твоей маме', text3: 'мамы', pre: 'eё', },
  2: {label: 'Папа',
   text: 'твой папа', text2: 'твоему папе', text3: 'папы', pre: 'eго'},
  3: {label: 'Брат', text: 'твой брат', text2: 'твоему брату', text3: 'брата', pre: 'eго'},
  4: {label: 'Сестра', text: 'твоя сестра', text2: 'твоей сестре', text3: 'сестры', pre: 'eё'},
  5: {label: 'Дядя', text: 'твой дядя', text2: 'твоему дяде', text3: 'дяди', pre: 'eго'},
  6: {label: 'Тётя', text: 'твоя тётя', text2: 'твоей тёте', text3: 'тёти', pre: 'eё'},
}

import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from 'react-redux'
import UserPic from '../common/user-pic2'
import { signIn, createPic, closeCreatePic, savePic } from '../../redux/modules/sign-in'

import Slider from "react-slick";

function CreatePic() {
  const slider = useRef(null)
  const slider2 = useRef(null)
  const open = useSelector((state) => state.auth.pic)
  const {gender} = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()

  const [face, setFace] = useState(null)
  const [body, setBody] = useState(null)
  const [trigger, setTrigger] = useState(false)

  // useEffect(() => {
  //   setFace(face ? face : 1)
  //   setBody(body ? body : 5)
  //   dispatch(savePic([face ? face : 1, body ? body : 5]))
  // }, [])

  if (!open) return null


  const handleChangeFace = (face) => {
    setFace(face)
    setBody(body ? body : 5)
    dispatch(savePic([face, body ? body : 5]))
  }

  const handleChangeBody = (body) => {
    setBody(body)
    setFace(face ? face : 1)
    dispatch(savePic([face ? face : 1, body]))
  }
  

  const options = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    afterChange: current =>{
       handleChangeBody(current + 5)
    }
  }


  const options2 = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    afterChange: current =>{
       handleChangeFace(current+1)
    }
  }

  const getBody = () => {
    return (
      <div className="pic-creator__container">
        <div className="pic-creator__photo__before"></div>
        <div className="pic-creator_inner1">
          <Slider {...options} ref={slider}>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={null} body={5} />
              </div>
            </div>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={null} body={6} />
              </div>
            </div>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={null} body={7} />
              </div>
            </div>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={null} body={8} />
              </div>
            </div>
          </Slider>
        </div>
        <div className="pic-creator_inner2">
          <Slider {...options2} ref={slider2}>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={1} body={null} />
              </div>
            </div>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={2} body={null} />
              </div>
            </div>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={3} body={null} />
              </div>
            </div>
            <div className="pic-slide">
              <div className="pic-creator__photo">
                <UserPic face={4} body={null} />
              </div>
            </div>
          </Slider>
        </div>
        <div className="pic-creator__photo__title">Твой <br/> аватар</div>

        <div className="pic-creator__prev1" onClick={e => slider.current.slickPrev()} />
        <div className="pic-creator__next1" onClick={e => slider.current.slickNext()} />

        <div className="pic-creator__prev2" onClick={e => slider2.current.slickPrev()} />
        <div className="pic-creator__next2" onClick={e => slider2.current.slickNext()} />

      </div>
    )
  }

  return (
    <div className="modal modal-pic visible">
      <div className="modal__overlay" onClick={() => dispatch(closeCreatePic())} />
      <div className="modal__container">
        <div className="modal__body">
          <div className="modal-pic__body">
            <div className="modal-pic__icon" />
            <div className="modal-pic__title" />
            <div className="pic-creator">
              {getBody()}
            </div>
            <div className="layer button button--2" onClick={() => dispatch(closeCreatePic(face && body ? [face, body] : null))}><img src="/images/button/2.svg" alt=""/></div>
            {/*<div className="btn" onClick={() => dispatch(closeCreatePic(face && body ? [face, body] : null))}><span>Готово!</span></div>*/}
          </div>
        </div>

        <div className="modal-helper"></div>
      </div>
    </div>
  )
}

export default CreatePic
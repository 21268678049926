import React from "react"
import Body from './body'
import { useDispatch, useSelector } from 'react-redux'
import { policyClose } from '../../../redux/modules/modal'

function Policy() {
  const { policy } = useSelector((state) => state.modal)
  const mobile = useSelector((state) => state.mobile)
  const dispatch = useDispatch()

  if (!policy) return null

  return (
    <div className="modal modal-policy visible">
      <div className="modal-container">
        <div className="snow-wrap2"><div className="powder" /></div>
        {mobile || <div className="content"><div className="btn3" onClick={() => dispatch(policyClose())}><span>Вернуться на главную</span></div></div>}
        <Body />
      </div>
    </div>
  )
}

export default Policy
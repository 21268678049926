import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import layout from '../../decorators/layout'
import { setLoading } from '../../redux/modules/loading'
import { signIn } from '../../redux/modules/sign-in'
import {signInImages, makeCandyImages} from '../../redux/config'
import Form from './form'
import CreatePic from '../modal/create-pic'
import CreatePicMobile from '../modal/create-pick-mobile'
import Policy from '../modal/privacy-policy'
import $ from 'jquery'
import './style.scss'

class SignIn extends Component {

  render() {
    const { loading, nextLoading, setLoading, mobile, height } = this.props
    const mob = mobile ? 'mob/' : ''

    if (loading) return <Loader loading={loading} setLoading={() => setLoading('signin')} images={signInImages} />

    return (
      <Fragment>
      {mobile && <div className="top-bg sign-in__topbg" style={{backgroundImage: 'url(/images/sign-in/mob/bgbg.png)'}} />}
      {mobile && <div className="powder" />}
      <div className="sign-in" style={mobile ? {minHeight: height + 'px'} : {}}>
        <img src={mobile ? '/images/mob1.png' : '/images/spacer-main.png'} alt=""/>
        <div className="spacer spacer1">
          <img src={mobile ? '/images/mob1.png' : '/images/spacer.png'} alt=""/>
          <div className="layer logo"><Link to="/" className="logo"><img src="/images/logo.svg" alt=""/></Link></div>
          {/*mobile || <div className="layer sign-in__bg1 sign-in__bg1--copy-left"><img src="/images/sign-in/bg.png" alt=""/></div>*/}
          {/*mobile || <div className="layer sign-in__bg1 sign-in__bg1--copy-right"><img src="/images/sign-in/bg.png" alt=""/></div>*/}
          {/*mobile || <div className="layer sign-in__bg2 sign-in__bg2--copy-right"><img src='/images/sign-in/bg2.png' alt=""/></div>*/}
          {/*mobile || <div className="layer sign-in__bg2 sign-in__bg2--copy-left"><img src="/images/sign-in/bg2.png" alt=""/></div>*/}

          {mobile || <div className="layer sign-in__bg1"><img src="/images/sign-in/bg.png" alt=""/></div>}
          {mobile || <div className="layer sign-in__bg2">
            <div className="powder" />
            <img src="/images/sign-in/bg2.png" alt=""/>
          </div>}
          <div className="layer sign-in__bubble"><img src={`/images/sign-in/${mob}bubble.png`} alt=""/></div>
          <div className="layer sign-in__logo"><img src="/images/sign-in/logo.svg" alt=""/></div>
          <div className="layer sign-in__guy">
            <img src="/images/sign-in/guy.png" alt=""/>
            <span></span>
          </div>
          {/*<div className="layer sign-in__btn btn btn2" onClick={this.handleScroll}><span>Хочу!!!</span></div>*/}
          <div className="layer button button--1 sign-in__btn" onClick={this.handleScroll}><img src="/images/button/1.svg" alt=""/></div>
        </div>
        <div className="spacer spacer2">
          {mobile || <img src='/images/spacer.png' alt=""/>}
          <div className="layer sign-in__candy2"><img src="/images/sign-in/candy2.png" alt=""/></div>
          <div className="layer sign-in__candy">
            <img src={`/images/sign-in/candy/empty.png`}alt=""/>
            <span className="img"><img src={`/images/sign-in/candy/1.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/2.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/3.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/5.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/6.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/7.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/8.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/9.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/10.png`}alt=""/></span>
            <span className="img"><img src={`/images/sign-in/candy/11.png`}alt=""/></span>
          </div>
          <div className="layer sign-in__candy-small">
            <img src={`/images/sign-in/candy/candy.png`}alt=""/>
          </div>
          {mobile && <span  ref={(r) => (this.scrollElement = r)}></span>}
          {mobile || <div className="layer sign-in__form"  ref={(r) => (this.scrollElement = r)}><img src='/images/sign-in/form.png' alt=""/></div>}
          <div className="layer sign-in__cake"><img src="/images/sign-in/cake.png" alt=""/></div>
          <div className="layer sign-in__robot"><img src="/images/robot1.png" alt=""/></div>
          <Form />
        </div>
        {nextLoading && <Loader nextStepLoader={true} loading={nextLoading} setLoading={()=> this.props.setLoading('candy')} images={makeCandyImages} />}
        {mobile ? <CreatePicMobile /> : <CreatePic />}
        <Policy />
      </div>
      </Fragment>
    )
  }
  
  handleScroll = () => {
    $('html, body').animate({
      scrollTop: $(this.scrollElement).offset().top
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    loading: state.loading.signin,
    nextLoading: state.loading.candy,
    height: state.height
  }),
  { setLoading }
)(layout(SignIn))

import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import UserPic from '../common/userpic'
import { signIn, createPic, closeCreatePic, savePic } from '../../redux/modules/sign-in'

function CreatePic() {
  const open = useSelector((state) => state.auth.pic)
  const {gender} = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()

  const [face, setFace] = useState(null)
  const [body, setBody] = useState(null)
  const [trigger, setTrigger] = useState(false)

  if (!open) return null

  const handleChangeFace = (face) => {
    setFace(face)
    setBody(body ? body : 5)
    dispatch(savePic([face, body ? body : 5]))
  }

  const handleChangeBody = (body) => {
    setBody(body)
    setFace(face ? face : 1)
    dispatch(savePic([face ? face : 1, body]))
  }

  const getBody = () => {
    return (
      <div className="pic-creator__container">
        <div className="pic-creator__container__title">Лицо:</div>
        <div className="pic-creator__face">
          <div><div className={face === 1 ? 'checked' : ''} onClick={() => handleChangeFace(1)}><img src={`/images/constructor/${gender}/1.png`} alt=""/></div></div>
          <div><div className={face === 2 ? 'checked' : ''} onClick={() => handleChangeFace(2)}><img src={`/images/constructor/${gender}/2.png`} alt=""/></div></div>
          <div><div className={face === 3 ? 'checked' : ''} onClick={() => handleChangeFace(3)}><img src={`/images/constructor/${gender}/3.png`} alt=""/></div></div>
          <div><div className={face === 4 ? 'checked' : ''} onClick={() => handleChangeFace(4)}><img src={`/images/constructor/${gender}/4.png`} alt=""/></div></div>
        </div>
        <div className="pic-creator__container__title">Туловище:</div>
        <div className="pic-creator__body">
          <div><div className={body === 5 ? 'checked' : ''} onClick={() => handleChangeBody(5)}><img src={`/images/constructor/${gender}/5.png`} alt=""/></div></div>
          <div><div className={body === 6 ? 'checked' : ''} onClick={() => handleChangeBody(6)}><img src={`/images/constructor/${gender}/6.png`} alt=""/></div></div>
          <div><div className={body === 7 ? 'checked' : ''} onClick={() => handleChangeBody(7)}><img src={`/images/constructor/${gender}/7.png`} alt=""/></div></div>
          <div><div className={body === 8 ? 'checked' : ''} onClick={() => handleChangeBody(8)}><img src={`/images/constructor/${gender}/8.png`} alt=""/></div></div>
        </div>
        
      </div>
    )
  }

  return (
    <div className="modal modal-pic visible">
      <div className="modal__overlay" onClick={() => dispatch(closeCreatePic())} />
      <div className="modal__container">
        <div className="modal__body">
          <div className="modal-pic__body">
            <div className="modal-pic__icon" />
            <div className="modal-pic__title" />
            <div className="pic-creator">
              <div className="pic-creator__photo">
                <UserPic />
                <div className="pic-creator__photo__title">Твой <br/> аватар</div>
              </div>
              {getBody()}
            </div>
            <div className="layer button button--2" onClick={() => dispatch(closeCreatePic(face && body ? [face, body] : null))}><img src="/images/button/2.svg" alt=""/></div>
            {/*<div className="btn" onClick={() => dispatch(closeCreatePic(face && body ? [face, body] : null))}><span>Готово!</span></div>*/}
          </div>
        </div>

        <div className="modal-helper"></div>
      </div>
    </div>
  )
}

export default CreatePic